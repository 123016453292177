document.addEventListener('DOMContentLoaded', function() {
  const paginationContainer = document.querySelector('.js-pagination');
  if (!paginationContainer) {
      return;
  }

  const links = document.querySelectorAll('.js-pagination-item');
  const totalPages = links.length;
  const currentPageElement = document.querySelector('.js-pagination-item--current');
  const currentPage = parseInt(currentPageElement.textContent);

  links.forEach(link => {
      link.style.display = 'none';
  });

  function displayLink(index) {
      if (index > 0 && index <= totalPages) {
          links[index - 1].style.display = 'inline';
      }
  }

  function adjustPaginationForScreenSize() {
      const windowWidth = window.innerWidth;
      const isMobile = windowWidth <= 600;

      links.forEach(link => {
          link.textContent = link.textContent.replace('...', '').trim();
      });

      displayLink(1);
      displayLink(totalPages);

      if (isMobile) {
          displayLink(currentPage);
          if (currentPage > 1) {
              displayLink(currentPage - 1);
          }
          if (currentPage < totalPages) {
              displayLink(currentPage + 1);
          }
      } else {
          for (let i = Math.max(1, currentPage - 2); i <= Math.min(totalPages, currentPage + 2); i++) {
              displayLink(i);
          }
      }

      if (currentPage - 2 > 2) {
          links[1].style.display = 'inline';
          links[1].textContent = '...';
      }
      if (currentPage + 2 < totalPages - 1) {
          links[totalPages - 2].style.display = 'inline';
          links[totalPages - 2].textContent = '...';
      }
  }

  adjustPaginationForScreenSize();

  window.addEventListener('resize', adjustPaginationForScreenSize);
});
