document.addEventListener('DOMContentLoaded', function () { 
  function navMenuController() {
    if ($(window).width() > 768) {
      // let navWrapperHeight = 0;
      // let navMenuHeight = 0;
      $('.navigation__button').click(function(event) {
        if ($('.navigation__wrapper').is(':visible')) {
          $('.navigation__wrapper').fadeOut();
          $(this).removeClass('navigation__button--active');
        } else {
          $('.navigation__wrapper').fadeIn();
          // $('.navigation__wrapper').fadeIn(400, function(){
          //   navWrapperHeight = $(this).height();
          //   let navMenu = $(this).find('.navigation__second-level');
          //   navMenuHeight = navMenu.height();

          //   console.log(navMenuHeight);

          //   if(navMenuHeight > navWrapperHeight) {
          //     navMenu.css({
          //       gridTemplateColumns: 'repeat(2, 1fr)',
          //       maxHeight: '100%',
          //       height: '100%'
          //     });
          //   } else {
          //     navMenu.css({
          //       gridTemplateColumns: 'repeat(1, 1fr)',
          //       maxHeight: '100%',
          //       height: '100%'
          //     });
          //   }
          // });
          $(this).addClass('navigation__button--active');
        }
        event.stopPropagation();
      });
      
      $(document).click(function(event) {
        var target = $(event.target);

        if (!target.closest('.navigation__button, .navigation__wrapper').length) {
          $('.navigation__wrapper').fadeOut();
          $('.navigation__button').removeClass('navigation__button--active'); 
        }
      });

      $('.navigation__wrapper').click(function(event) {
        event.stopPropagation();
      });

      $('.navigation__first-level-item').on('mouseenter', function() {
        $(this).addClass('navigation__first-level-item--active');
        $('.navigation__wrapper').css( "width", "100%" );
        $(this).find('.navigation__second-level').css( "visibility", "visible" );
      }).on('mouseleave', function() {
        $(this).removeClass('navigation__first-level-item--active');
        $('.navigation__wrapper').css( "width", "fit-content" );
        $(this).find('.navigation__second-level').css( "visibility", "hidden" );
      });

      // let isDown = false;
      // let startX;
      // let scrollLeft;

      // $('.navigation__second-level').on('mousedown', function(e) {

      //   isDown = true;
      //   $(this).addClass('grabbing');
      //   startX = e.pageX - $(this).offset().left;
      //   scrollLeft = $(this).scrollLeft();

      // }).on('mouseleave', function() {

      //   isDown = false;
      //   $(this).removeClass('grabbing');

      // }).on('mouseup', function() {

      //   isDown = false;
      //   $(this).removeClass('grabbing');

      // }).on('mousemove', function(e) {

      //   if (!isDown) return;
      //   e.preventDefault();
      //   let x = e.pageX - $(this).offset().left;
      //   let walk = (x - startX) * 1.5; 
      //   $(this).scrollLeft(scrollLeft - walk);

      // });
    } else {

      $('.navigation__first-level-item').click(function(event) {

        let isAlreadyVisible = $(this).find('.navigation__second-level').is(':visible');

        $('.navigation__second-level').fadeOut();
        $('.navigation__subtitle').removeClass('navigation__subtitle--opened');

        if (!isAlreadyVisible) {
          $(this).find('.navigation__second-level').fadeIn();
          $(this).find('.navigation__subtitle').addClass('navigation__subtitle--opened');
        }
        
        event.stopPropagation();
      });
      
      $(document).click(function() {
        $('.navigation__second-level').fadeOut();
      });

      $('.navigation__second-level').click(function(event) {
        event.stopPropagation();
      });
    };
  };
  navMenuController();
});
