import Swiper from 'swiper';
import 'swiper/bundle';
import 'swiper/css/bundle';

window.initProductSwiper = function() {
  if (productSwiperBullets) {
    productSwiperBullets.destroy(true, true);
  }
  if (productSwiper) {
    productSwiper.destroy(true, true);
  }

  let productSwiperBullets = new Swiper('.js-slider-bullets', {
    slidesPerView: 'auto',
    spaceBetween: 8,
    loop: false,
    autoplay: false,
    direction: 'vertical',
    freeMode: {
      enabled: true,
      sticky: true,
    },
    navigation: {
      nextEl: '.js-slider-bullets--next',
      prevEl: '.js-slider-bullets--prev',
    },
  });

  let productSwiper = new Swiper('.js-slider-main', {
    slidesPerView: 1,
    loop: false,
    autoplay: false,
    thumbs: {
      swiper: productSwiperBullets,
    },
    allowTouchMove:true,
    effect: "fade",
    speed: 0,
    pagination: {
      el: ".js-slider-main-pagination",
    },
    breakpoints: {
      768: {
        allowTouchMove:false,
        pagination: false,
      }
    }
  });

  productSwiper.init();

  initGallerySliders();
};

function initGallerySliders() {

  let gallerySliders = document.querySelectorAll('.js-gallery-slider-wrapper');

  gallerySliders.forEach(function(gallerySliderItem) {

    if (gallerySliderItem.classList.contains('inited')) {
      gallerySliderItem.destroy(true, true);
    }

    gallerySliderItem.classList.add('inited');

    const prevButton = gallerySliderItem.querySelector('.js-gallery-navigation--prev');
    const nextButton = gallerySliderItem.querySelector('.js-gallery-navigation--next');

    const gallerySwiper = new Swiper(gallerySliderItem.querySelector('.js-gallery-slider'), {
      slidesPerView: 1.2,
      spaceBetween: 10,
      breakpoints: {
        320: {
          slidesPerView: 1.2,
          spaceBetween: 16,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 16,
        },
        920: {
          slidesPerView: 4,
          spaceBetween: 16,
        },
        1280: {
          slidesPerView: 5,
          spaceBetween: 16,
        },
      },
      loop: false,
      grabCursor: false,
      allowTouchMove: true,
      navigation: {
        nextEl: nextButton,
        prevEl: prevButton,
      },
    });

    gallerySwiper.init();

  });
};

