import 'babel-polyfill';
import {importAll,} from './webpack/_helpers';
import './src/assets/scripts/layout';

// Импорт всех стилей компонентов
import '@views/_startup.scss';


// Импорт скриптов всех компонентов
// Важно! Используется jquery
// importAll(require.context('./src/views/_pages/', true, /\.js$/));
importAll(require.context('./src/views/components/', true, /\.js$/));

// Импорт svg для составления спрайта
importAll(require.context('./src/assets/svg/', true, /\.svg$/));
