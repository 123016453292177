(function ($) {
	(!window.Runway) && (window.Runway = {});

	$(document).ready(() => {
		initDropdowns();
	});
	// eslint-disable-next-line no-undef
})(jQuery)

window.initDropdowns = function(){
	document.querySelectorAll("[data-dropdown]").forEach((dropdown) => {
		if (!$(dropdown).hasClass('inited')) {
			dropdown.addEventListener("click", function (e) {
				e.stopPropagation();
				e.target.closest(".base-dropdown").classList.toggle("active");
			});
			$(dropdown).addClass('inited');
		}
	});

	document.querySelectorAll("[data-option]").forEach((item) => {
		if (!$(item).hasClass('inited')) {
			item.addEventListener("click", function (e) {
				const dropdown = e.target.closest(".base-dropdown");
				const input = dropdown.querySelector("input[data-current-value]");
				const field = dropdown.querySelector('input[name="' + $(dropdown).data('field') + '"]');

				if (dropdown) {
					dropdown.classList.remove("active");
				}
				if (input) {
					input.value = e.target.dataset.label;
				}
				if (field) {
					field.value = e.target.dataset.value;
					const form = field.closest("form");
					form.submit();
				}
			});
			$(item).addClass('inited');
		}
	});
};