(function($){
  (!window.Runway) && (window.Runway = {});

  function setCookie(name, value, expDays = 100) {
      const d = new Date();
      d.setTime(d.getTime() + (expDays * 24 * 60 * 60 * 1000));
      const expires = "expires=" + d.toGMTString();
      document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }

  function getCookie(name) {
      const cname = name + "=";
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
              c = c.substring(1);
          }
          if (c.indexOf(cname) == 0) {
              return c.substring(cname.length, c.length);
          }
      }
      return "";
  }

  function changeListView(view = 'grid') {
      const list = document.querySelector('[data-view-controller="js-change-view"]');
      if (!list || list?.dataset?.view === view) return;
      list.dataset.view = view;
  }


  function setListView(view = 'grid') {
      setCookie('list-view', view);
      changeListView(view);
  }

  function init() {
      const listView = getCookie('list-view') || 'grid';
      changeListView(listView);
      document.querySelectorAll('input[name="view"]').forEach((input) => {
          input.checked = input.value === listView;
      });

  };

  $(document).ready(()=>{

      /**
       * @param {String | String[]} selector
       * @param {string} className
       * @param {'toggle', 'add', 'remove'} action
       */
      function doActionClassName(selector, className, action = 'toggle') {

          try {
              if (typeof selector === 'string') {
                  document.querySelectorAll(selector).forEach((node) => { node.classList[action](className); });
              }

              if (Array.isArray(selector)) {
                  selector.forEach((s) => document.querySelectorAll(s).forEach((node) => { node.classList[action](className); }));
              }
          } catch (e) {
              console.error(e);
          }
      }

      window.Runway.doActionClassName = doActionClassName;

      // При первой загрузке отсутствует DOM дерево
          init();
  });

// Отрабатывает при перезагрузке страницы
init();

window.Runway.setCookie = setCookie;
window.Runway.getCookie = getCookie;
window.Runway.changeListView = changeListView;
window.Runway.setListView = setListView;

// eslint-disable-next-line no-undef
})(jQuery)
